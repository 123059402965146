import { SidebarItemType } from '../useGetSidebarItems';

export const getNestedKeyPath = (item: SidebarItemType): string => {
  let path = item.key;

  // Boucle tant qu'il y a des enfants non falsy
  while (item.children && item.children.filter(Boolean).length > 0) {
    // Filtre les valeurs falsy dans les enfants
    const validChildren = item.children.filter(Boolean) as SidebarItemType[];

    // Prend le premier enfant valide
    item = validChildren[0];

    // Ajoute la clé au chemin
    path += `${item.key}`;
  }

  return path;
};
