import React from 'react';
import { BirdzTitle, InputField } from '@applications-terrains/birdz-react-library';
import { Grid } from '@mui/material';

export const ExportFormatSettingsArray = () => {
  return (
    <>
      <BirdzTitle>Format</BirdzTitle>
      <Grid container alignItems="center" spacing={1}>
        <Grid alignItems={'center'} item xs={4}>
          Séparateur
        </Grid>
        <Grid alignItems={'center'} item xs={8}>
          <InputField name={'array_separator' || null} fullWidth size="small" />
        </Grid>
      </Grid>
    </>
  );
};
