import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import * as Yup from 'yup';
import { Box, FormControl } from '@mui/material';
import { Pricing } from '../../../types';
import {
  InputField,
  DatePickerField,
  SelectField,
  useNotif,
  showFriendlyErrorsHTML,
  BirdzNotif,
  ListField,
  ListPage
} from '@applications-terrains/birdz-react-library';
import { formatToSelectOptions, useGetYears } from '../../../hooks/datarefs';

const ApplicablePricings = () => {
  const [listIsVisible, setListIsVisible] = useState<boolean>(true);
  const { id } = useParams();
  const endpoint = `/api/boi/subcontractors/subcontractors/${id}/`;
  const deleteApplicablePricings = '/api/boi/subcontractors/applicable-pricing/';
  const createApplicablePricing = '/api/boi/subcontractors/applicable-pricing/';
  const { notif, notifOptions } = useNotif();
  const { data: years } = useGetYears();
  const [listFields, setListFields] = useState<ListField[]>([]);

  const resultsField = 'applicable_pricings';

  const errorsTranslations = {
    end_date: {
      translation: 'date de fin',
      message: {
        original: 'end_date must occur after start_date',
        translation: 'La date de fin doit être supérieure à la date de début'
      }
    }
  };

  type ErrorObj = { [key: string]: string[] };

  const getTranslatedObj = (errors: ErrorObj) => {
    const translatedObj: ErrorObj = {};
    Object.entries(errors).forEach(([key, value]) => {
      if (key in errorsTranslations) {
        const targetKey = key as keyof typeof errorsTranslations;
        const keyToDisplay = errorsTranslations[targetKey].translation;
        const message = errorsTranslations[targetKey].message;
        const messageToDisplay = value.map((val) =>
          val === message.original ? message.translation : val
        );
        translatedObj[keyToDisplay] = messageToDisplay;
      } else translatedObj[key] = value;
    });
    return translatedObj;
  };

  useEffect(() => {
    const listFields = [
      {
        name: 'year',
        label: 'Année',
        transform: (value: any, row: any) => row?.year__label,
        options: {
          renderForm: () => (
            <FormControl fullWidth>
              <SelectField
                name="year"
                options={years ? formatToSelectOptions(years) : []}
                label="Année"
              />
            </FormControl>
          )
        },
        width: '130px'
      },
      {
        name: 'product_subcontractor_name',
        label: 'Libellé',
        options: {
          renderForm: () => (
            <InputField
              name="product_subcontractor_name"
              type="text"
              label="Libellé"
              size="small"
            />
          )
        },
        width: '250px'
      },
      {
        name: 'product_accounting_code',
        label: 'Code produit (Birdz)',
        options: {
          renderForm: () => (
            <InputField
              name="product_accounting_code"
              type="text"
              label="Code produit (Birdz)"
              size="small"
            />
          )
        },
        width: '250px'
      },
      {
        name: 'product_accounting_name',
        label: 'Libellé (Birdz)',
        options: {
          renderForm: () => (
            <InputField
              name="product_accounting_name"
              type="text"
              label="Libellé (Birdz)"
              size="small"
            />
          )
        },
        width: '250px'
      },
      {
        name: 'price',
        label: 'Montant par unité',
        options: {
          renderForm: () => (
            <InputField name="price" type="number" label="Montant par unité" size="small" />
          )
        }
      },
      {
        name: 'start_date',
        label: 'Date début',
        transform: (date: string) => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        options: {
          renderForm: () => (
            <DatePickerField
              name="start_date"
              inputFormat="dd/MM/yyyy"
              time={false}
              label="Date de début"
              size="small"
            />
          )
        },
        width: '190px'
      },
      {
        name: 'end_date',
        label: 'Date fin',
        transform: (date: string) => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        options: {
          renderForm: () => (
            <DatePickerField
              name="end_date"
              inputFormat="dd/MM/yyyy"
              time={false}
              label="Date de fin"
              size="small"
            />
          )
        },
        width: '190px'
      },
      {
        name: 'max_usage_limit',
        label: 'Nb max par intervention',
        transform: (data: null | number) => data || '-',
        options: {
          renderForm: () => (
            <InputField
              name="max_usage_limit"
              type="number"
              label="Nb max par intervention"
              size="small"
            />
          )
        },
        width: '190px'
      },
      {
        name: 'capex_opex',
        label: 'C/O',
        options: {
          renderForm: () => (
            <FormControl fullWidth>
              <SelectField
                options={[
                  { value: 'CAPEX', label: 'CAPEX' },
                  { value: 'OPEX', label: 'OPEX' }
                ]}
                name="capex_opex"
                label="C/O"
              />
            </FormControl>
          )
        },
        width: '150px'
      }
    ];
    setListFields(listFields);
  }, [years]);

  const refreshList = () => {
    setListIsVisible(false);
    setTimeout(() => {
      setListIsVisible(true);
    });
  };

  const onSave = (values: Pricing) => {
    const payload = Object.assign({}, values);
    payload.subcontractor = id ? parseInt(id) : undefined;

    if (values?.id) {
      return axios.put(`${createApplicablePricing}${values.id}/`, payload).then(
        () => {
          notif({
            type: 'success',
            content: 'Le tarif a été modifié avec succès'
          });
          refreshList();
        },
        (error: any) => {
          const errors = error?.response?.data;
          const translatedObj = getTranslatedObj(errors);
          notif({
            type: 'error',
            content: showFriendlyErrorsHTML(
              translatedObj,
              'Une erreur est survenue lors de la modification du tarif'
            )
          });
        }
      );
    } else {
      return axios.post(createApplicablePricing, payload).then(
        () => {
          notif({
            type: 'success',
            content: 'Le tarif a été créé avec succès'
          });
          refreshList();
        },
        (error: any) => {
          const errors = error?.response?.data;
          const translatedObj = getTranslatedObj(errors);
          notif({
            type: 'error',
            content: showFriendlyErrorsHTML(
              translatedObj,
              'Une erreur est survenue lors de la création du tarif'
            )
          });
        }
      );
    }
  };

  const onDelete = (values: Pricing) =>
    axios.delete(deleteApplicablePricings + values.id + '/').then(() => {
      refreshList();
    });

  return (
    <Box sx={{ mt: 2 }}>
      <h3>Tarifs applicables</h3>
      {listIsVisible && (
        <ListPage
          endpoint={endpoint}
          fields={listFields}
          resultsField={resultsField}
          inlineEditOptions={{
            onSave: onSave,
            onDelete: onDelete,
            validationSchema: Yup.object().shape({
              year: Yup.number().required('Champ obligatoire'),
              price: Yup.number().required('Champ obligatoire'),
              product_accounting_code: Yup.string().required('Champ obligatoire'),
              product_accounting_name: Yup.string().required('Champ obligatoire'),
              product_subcontractor_name: Yup.string().required('Champ obligatoire'),
              start_date: Yup.date()
                .typeError("La date n'est pas au bon format")
                .required('Champ obligatoire'),
              end_date: Yup.date()
                .typeError("La date n'est pas au bon format")
                .required('Champ obligatoire'),
              capex_opex: Yup.string().required('Champ obligatoire'),
              max_usage_limit: Yup.number().nullable()
            })
          }}
          displayPaginationOptions={false}
        />
      )}
      <BirdzNotif options={notifOptions} />
    </Box>
  );
};

export default ApplicablePricings;
