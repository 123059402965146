import { omit } from 'lodash';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

type Value = string | number | object | unknown;
type GlobalContextType = {
  values: { [key: string]: Value };
  setValues: React.Dispatch<React.SetStateAction<{ [key: string]: Value }>>;
};

const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);

  if (context === undefined) {
    throw new Error('useGlobalContext must be used with a GlobalContext');
  }

  return context;
};

export const GlobalContextProvider = ({ children }: { children: ReactNode }) => {
  const [values, setValues] = useState<GlobalContextType['values']>({});
  const location = useLocation();

  useEffect(() => {
    !location.pathname.includes('bconnect/extractor/interventions') &&
      setValues((filters) => omit(filters, ['interSearchForm']));
  }, [location]);

  const value = {
    values,
    setValues
  };

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};
