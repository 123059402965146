import React, { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { FormikErrors } from 'formik';
import OptionLabel from './OptionLabel';
import { useParams } from 'react-router-dom';

interface ClientProps {
  value: number;
  label: string;
}

const ClientNameField = ({
  setFieldValue,
  name
}: {
  name: ClientProps;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<any>>;
}) => {
  const [clientNames, setClientNames] = useState<{ name: string; bconnect_id: string }[]>([]);

  const { id } = useParams();

  useEffect(() => {
    axios.get(`/api/boi/extractor/bconnect-clients/`).then((response: AxiosResponse) => {
      const clientNames = response.data;
      setClientNames(clientNames);
    });
  }, []);

  return (
    <OptionLabel
      values={clientNames}
      id={id}
      name={name}
      updateValue={(event: any) => {
        if (event) {
          setFieldValue('name', event.label);
          setFieldValue('bconnect_id', event.value);
        } else {
          setFieldValue('name', '');
          setFieldValue('bconnect_id', '');
        }
      }}
    />
  );
};

export default ClientNameField;
