import React from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RemoveIcon from '@mui/icons-material/Remove';
import { SidebarItemType } from '../useGetSidebarItems';
import { SvgIconComponent } from '@mui/icons-material';
import { Box } from '@mui/material';

export const ArrowIcon = () => <KeyboardArrowRightIcon className="arrowIcon" />;

export const ItemIcon = ({
  level,
  icon,
  isActive
}: {
  level: number;
  icon: SvgIconComponent | null;
  isActive: boolean;
}) => (level === 1 && isActive ? <ArrowForwardIcon /> : icon || <RemoveIcon />) as JSX.Element;

const Item = ({
  item,
  level = 0,
  path,
  collapsed
}: {
  item: SidebarItemType;
  level?: number;
  path: string;
  collapsed?: boolean;
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  let isActive = pathname === path;

  if (!isActive && pathname.includes(path)) {
    ['edit', 'view', 'add', 'details', 'new', 'create'].some((keyword) => {
      const condition = location.pathname.includes(keyword);
      if (condition) {
        isActive = true;
        return condition;
      }
    });
  }

  if (item.activeRoutes) {
    for (let index = 0; index < item.activeRoutes.length; index++) {
      const route = item.activeRoutes[index];
      if (pathname.includes(route)) {
        isActive = true;
        break;
      }
    }
  }

  return (
    <Box
      className={`d-flex w-100 align-items-center sidebar-item-child level-${level} ${isActive ? 'activeItem' : ''} ${collapsed ? 'collapsed' : ''}`}
      onClick={() => {
        level > 0 && navigate(path);
      }}
    >
      <ItemIcon level={level} icon={item.icon} isActive={isActive} />
      <Box className="title">{item.title}</Box>
      {level > 0 && item.children && item?.children?.length > 0 && <ArrowIcon />}
    </Box>
  );
};

export default Item;
