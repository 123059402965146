import React, { useEffect, useState } from 'react';
import { AppPaper, BirdzTitle, FormField, Item } from '@applications-terrains/birdz-react-library';
import { Box } from '@mui/material';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ContractsDropdown from '../../Contracts/ContractsDropdown';
import useContractsData from '../../Contracts/hooks/useContractsData';

const ConfigurationDetail = () => {
  const endpoint = '/api/bstock/configurations/';
  const { id } = useParams();
  const [protocols, setProtocols] = useState<{ id: number; label: string }[]>([]);
  const [selectAllContracts, setSelectAllContracts] = useState<boolean>(false);
  const { contractsData, allSelected } = useContractsData(endpoint);

  useEffect(() => {
    protocols.length === 0 &&
      axios.get('/api/boi/params/protocols/').then((res) => setProtocols(res.data.results));
  }, [protocols]);

  const formFields: FormField[] = [
    {
      name: 'name',
      type: 'text',
      label: 'Nom de la configuration*',
      validation: Yup.string().required('Merci de renseigner le nom')
    },
    {
      name: 'select_all_contracts',
      type: 'custom',
      label: '',
      render: (_, onChange: (value: boolean) => void) => {
        return <AllContracts onChange={onChange} selectAllContracts={selectAllContracts} />;
      }
    },
    {
      name: 'contracts',
      type: 'custom',
      label: 'Clients*',
      validation: Yup.array().of(Yup.string()),
      render: (value: string[], onChange: any) => {
        return (
          <Box sx={{ marginTop: '-8px' }}>
            <ContractsDropdown
              required={true}
              isMulti={true}
              value={value}
              onChange={(contractNbs) => {
                onChange(contractNbs);
              }}
              contractsData={contractsData || []}
              searchKey="contract_ve"
              selectAll={setSelectAllContracts}
              allSelected={allSelected ?? undefined}
            />
          </Box>
        );
      }
    },
    {
      name: 'protocol',
      label: 'Protocole*',
      type: 'select',
      validation: Yup.string().required('Merci de choisir un protocole'),
      options: {
        values: protocols?.map((protocol) => ({ label: protocol.label, value: protocol.id }))
      }
    },
    {
      name: 'dataset_code',
      type: 'text',
      label: 'Dataset*',
      validation: Yup.string().required('Merci de renseigner le dataset')
    },
    {
      name: 'dst',
      type: 'checkbox',
      label: 'DST'
    },
    {
      name: 'autoconnect',
      type: 'checkbox',
      label: 'Auto-connect'
    },
    {
      name: 'time_index',
      type: 'checkbox',
      label: 'Index horaire'
    }
  ];
  return (
    <AppPaper>
      <BirdzTitle>{id ? 'Modifier' : 'Ajouter'} une configuration</BirdzTitle>
      <AppPaper>
        <Box sx={{ mt: 3 }}>
          <Item action={id ? 'edit' : 'add'} endpoint={endpoint} fields={formFields} name="" />
        </Box>
      </AppPaper>
    </AppPaper>
  );
};

export default ConfigurationDetail;

const AllContracts = ({
  onChange,
  selectAllContracts
}: {
  onChange: (value: boolean) => void;
  selectAllContracts: boolean;
}) => {
  useEffect(() => {
    onChange(selectAllContracts);
  }, [selectAllContracts]);
  return <></>;
};
