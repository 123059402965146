import React from 'react';
import {
  ListPage,
  BirdzDialog,
  useDialog,
  AppPaper,
  BirdzTitle
} from '@applications-terrains/birdz-react-library';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button, Grid, IconButton } from '@mui/material';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import Delete from '@mui/icons-material/Delete';
import { useFetchContext } from '../../../../contexts/fetchContext';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Edit from '@mui/icons-material/Edit';
import { ExportListContextProvider } from './contexts/exportListContext';
import { PiFileCsv } from 'react-icons/pi';

type Export = {
  id: number;
  name: string;
};

const ExportsList = () => {
  const endpoint = '/api/boi/breader/exports/';
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();
  const fetchContext = useFetchContext();

  const listFields = [
    {
      name: 'client_name',
      label: 'Client'
    },
    { name: 'export_type', label: "Type d'export" },
    {
      name: 'format',
      label: 'Format',
      transform: (format: 'CSV') => {
        const icons = {
          CSV: <PiFileCsv color="#0288d1" fontSize="1.5em" />
        };
        return (
          <div style={{ width: '30px' }} className="d-flex align-items-center">
            {format in icons ? icons[format] : '-'}
          </div>
        );
      }
    }
  ];

  const actions = [
    {
      name: 'modifier',
      label: "Modifier l'export",
      render: (exportRow: Export) => {
        return (
          <IconButton component={Link} to={`/breader/exports/details/${exportRow.id}`}>
            <Edit fontSize="small" color="action" />
          </IconButton>
        );
      }
    },
    {
      name: 'delete-export',
      label: "Supprimer l'export",
      render: (exportRow: { id: number }) => {
        return (
          <IconButton
            onClick={() => {
              confirmDialog({
                title: "Supprimer l'export",
                content: 'Êtes-vous sûr de vouloir supprimer cet export ?',
                onValidate: () => {
                  axios.delete(`${endpoint}/${exportRow.id}`).then(
                    () => {
                      notif({
                        content: "L'export a été supprimé avec succès",
                        type: 'success'
                      });
                      fetchContext.toggleNeedToFetch(true);
                    },
                    () => {
                      notif({
                        content: 'Une erreur est survenue lors de la suppression',
                        type: 'error'
                      });
                    }
                  );
                  closeDialog();
                },
                onCancel: () => {
                  closeDialog();
                }
              });
            }}
          >
            <Delete fontSize="small" color="action" />
          </IconButton>
        );
      }
    }
  ];

  const searchFields = [
    {
      name: 'client_name',
      label: 'Client',
      options: {
        identifier: 'name',
        source: '/api/boi/breader/clients/',
        label: 'name',
        searchIsMulti: false
      }
    }
  ];

  return (
    <ExportListContextProvider>
      <AppPaper>
        <Grid justifyContent="space-between" container>
          <Grid item>
            <BirdzTitle>Administration des exports</BirdzTitle>
          </Grid>
          <Grid item>
            <Link to="/breader/exports/add">
              <Button variant="contained" sx={{ mt: 3 }}>
                <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un export
              </Button>
            </Link>
          </Grid>
        </Grid>
        {!fetchContext.needToFetch && (
          <ListPage
            endpoint={endpoint}
            fields={listFields}
            actions={actions}
            searchFields={searchFields}
          />
        )}
        <BirdzDialog options={dialogOptions} />
        <BirdzNotif options={notifOptions} />
      </AppPaper>
    </ExportListContextProvider>
  );
};

export default ExportsList;
