import React from 'react';
import {
  ListPage,
  BirdzDialog,
  useDialog,
  AppPaper,
  BirdzTitle,
  FormField,
  useNotif,
  BirdzNotif,
  Item,
  SearchField
} from '@applications-terrains/birdz-react-library';
import './Users.scss';
import { Link } from 'react-router-dom';
import moment from 'moment';
import * as Yup from 'yup';
import axios from 'axios';
import { Button, Grid, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { authService } from '../../..';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { formatToSelectOptions, useGetGroups, useGetSubcontractors } from '../../../hooks/datarefs';
import ContractsDropdown from '../../Contracts/ContractsDropdown';

const Users = (params: any) => {
  const endpoint = '/api/boi/administration/users/';
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();
  const { data: groups } = useGetGroups();
  const { data: subcontractors } = useGetSubcontractors();

  const transformItemOnSave = (user: any) => {
    if (!user.password) {
      delete user.password;
    }
    return { ...user, contracts: user.contracts === null ? [] : user.contracts };
  };

  const formFields: FormField[] = [
    {
      name: 'name',
      type: 'text',
      label: 'Nom',
      validation: Yup.string().required('Vous devez renseigner le nom')
    },
    {
      name: 'email',
      type: 'text',
      label: 'Email',
      validation: Yup.string()
        .email('Vous devez renseigner un e-mail valide')
        .required("L'email ne peut être vide")
    },
    {
      name: 'password',
      type: 'password',
      label: 'Mot de passe'
    },
    {
      name: 'is_active',
      type: 'radio',
      label: 'Etat',
      options: {
        values: [
          { value: true, label: 'Actif' },
          { value: false, label: 'Inactif' }
        ]
      },
      defaultValue: true,
      permissions: ['IS_ACTIVE']
    },
    { name: 'avatar', type: 'image', label: 'Photo' },

    {
      name: 'groups',
      type: 'select-multiple',
      label: 'Groupes rattachés',
      options: {
        values: formatToSelectOptions(groups, {
          label: 'name'
        })
      },
      validation: Yup.array().min(1, 'Vous devez choisir au moins un groupe pour cet utilisateur')
    },
    {
      name: 'subcontractor',
      type: 'select',
      label: 'Sous Traitant',
      options: {
        values: formatToSelectOptions(subcontractors, {
          label: 'name'
        })
      }
    },
    {
      name: 'contracts',
      type: 'custom',
      label: 'Contrat / client',
      render: (value: string[], onChange: any) => {
        return <ContractsDropdown value={value} onChange={(data) => onChange(data)} />;
      }
    },
    {
      name: 'used_quota',
      type: 'readonly',
      label: 'Quota utilisé pour le mois en cours'
    },
    {
      name: 'last_login',
      type: 'readonly',
      label: 'Dernière connexion',
      transform: (value: any) => {
        const date = value && moment(value).format('DD/MM/YYYY à HH:mm:ss');
        return date || '';
      }
    }
  ];

  const listFields = [
    {
      name: 'name',
      label: 'Nom',
      orderable: true
    },
    {
      name: 'groups',
      label: 'Groupes',
      orderable: true,
      transform: (values: any) => {
        return values
          .map((value: number) => {
            const group = groups?.find((dataServiceGroup: any) => dataServiceGroup.id === value);
            return group?.name || value;
          })
          .join(', ');
      }
    },
    {
      name: 'subcontractor__name',
      label: 'Sous Traitants',
      options: {
        width: '170px'
      }
    },
    {
      name: 'is_active',
      label: 'Actif',
      className: 'text-center',
      orderable: true,
      options: { width: '130px' }
    },
    {
      name: 'last_login',
      label: 'Dernière connexion',
      transform: (value: any) => {
        return (value && moment(value).format('DD/MM/YYYY à HH:mm:ss')) || '';
      },
      orderable: true,
      options: { width: '190px' }
    }
  ];

  const searchFields: SearchField[] = [
    {
      name: 'id',
      label: 'Nom',
      options: {
        source: '/api/boi/administration/users/',
        searchIsMulti: false
      }
    },
    {
      name: 'subcontractor',
      label: 'Sous-traitant',
      options: {
        source: '/api/boi/subcontractors/subcontractors/?is_active=true&',
        searchIsMulti: false
      }
    },
    {
      name: 'groups',
      label: 'Groupe',
      options: {
        source: '/api/boi/administration/groups/?is_active=true&',
        searchIsMulti: false
      }
    }
  ];

  const actions = [
    {
      name: 'edit',
      label: "Modifier l'utilisateur",
      render: (user: any) => {
        return (
          <IconButton component={Link} to={`/boi/administration/users/edit/${user.id}`}>
            <EditIcon fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'delete-user',
      label: "Supprimer l'utilisateur",
      render: (model: any) => {
        return (
          <IconButton
            onClick={() => {
              confirmDialog({
                title: "Supprimer l'utilisateur",
                content: 'Êtes-vous sûr de vouloir supprimer cet utilisateur?',
                onValidate: () => {
                  axios.delete(`${endpoint}${model.id}/`).then(
                    () => {
                      notif({
                        content: "L'utilisateur a été supprimé avec succès",
                        type: 'success'
                      });
                      document.location.reload();
                    },
                    () => {
                      notif({
                        content: 'Une erreur est survenue lors de la suppression',
                        type: 'error'
                      });
                    }
                  );
                  closeDialog();
                },
                onCancel: () => {
                  closeDialog();
                }
              });
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        );
      }
    }
  ];

  const connectAsAction: any = {
    name: 'connect-as',
    label: 'Se connecter en tant que',
    render: (user: any) => {
      return (
        <IconButton
          id="connectAs"
          onClick={() => {
            axios.post('/api/auth/as/', { user_id: user.id }).then(
              () => {
                notif({
                  type: 'success',
                  content: (
                    <>
                      Vous êtes maintenant connecté en tant que <strong>{user.name}</strong>.<br />
                      La page va maintenant se recharger
                    </>
                  )
                });

                setTimeout(() => {
                  window.location.href = '/';
                }, 2500);
              },
              (error) => {
                let content = 'Erreur';
                if (error.response.status === 401) {
                  content = "Vous n'êtes pas autorisés à utiliser cette fonction";
                }

                notif({
                  type: 'error',
                  content
                });
              }
            );
          }}
        >
          <ManageAccountsIcon fontSize="small" />
        </IconButton>
      );
    }
  };

  if (authService.canAccess('LOGIN_AS')) {
    actions.push(connectAsAction);
  }

  return (
    <AppPaper>
      {params.action === 'list' && (
        <>
          <Grid justifyContent="space-between" container>
            <Grid item>
              <BirdzTitle>Liste des utilisateurs</BirdzTitle>
            </Grid>
            <Grid item>
              <Link to="/boi/administration/users/create">
                <Button variant="contained" sx={{ mt: 3 }}>
                  <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un utilisateur
                </Button>
              </Link>
            </Grid>
          </Grid>

          <ListPage
            endpoint={endpoint}
            fields={listFields}
            actions={actions}
            searchFields={searchFields}
          />
          <BirdzDialog options={dialogOptions} />
        </>
      )}
      {(params.action === 'add' || params.action === 'edit') && (
        <Item
          action="add"
          endpoint={endpoint}
          fields={formFields}
          name={`${params.action === 'add' ? 'Ajouter' : 'Modifier'} un utilisateur`}
          transformItemOnSave={transformItemOnSave}
        />
      )}
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
};

export default Users;
