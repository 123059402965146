import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import packageJson from '../../../package.json';
import './Sidebar.scss';
import { useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { authService } from '../..';
import SidebarItem from './SidebarItem/SidebarItem';
import axios from 'axios';
import useGetSidebarItems from './useGetSidebarItems';
import MenuIcon from '@mui/icons-material/Menu';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ThemeSelector from './ThemeSelector';
import { ApiVersion, SidebarTheme } from './types';
import SidebarInfos from './SidebarInfos';
import { environnements } from './const';

const Sidebar = ({ ...props }) => {
  const navigate = useNavigate();
  const [sidebarIsExpanded, setSidebarIsExpanded] = useState<boolean>(true);
  const [theme, setTheme] = useState<SidebarTheme>('base');
  const [apiVersion, setApiVersion] = useState<ApiVersion>();
  const [showUiVersion, setShowUiVersion] = useState<boolean>();
  //eslint-disable-next-line
  const { staticContext, ...rest } = props;
  const appVersion = packageJson?.version;

  const sidebarItems = useGetSidebarItems();

  useEffect(() => {
    // Load expanded menu pref stored in localstorage
    const sidebarIsExpanded = JSON.parse(localStorage.getItem('sidebarIsExpanded') || 'true');
    if (typeof sidebarIsExpanded === 'boolean') {
      setSidebarIsExpanded(sidebarIsExpanded);
      setExpandedCssClasses(sidebarIsExpanded);
    }

    const sidebarTheme = localStorage.getItem('sidebarTheme');
    if (sidebarTheme && sidebarTheme !== theme) {
      authService.canAccessBOI() && setTheme(sidebarTheme as SidebarTheme);
    }

    axios.get('/api/version/').then((response) => {
      setApiVersion(response?.data);
    });
  }, [theme, authService]);

  const toggleBurger = () => {
    localStorage.setItem('sidebarIsExpanded', !sidebarIsExpanded + '');
    setExpandedCssClasses(!sidebarIsExpanded);
    setSidebarIsExpanded(!sidebarIsExpanded);
  };

  const setExpandedCssClasses = (menuIsExpanded: boolean) => {
    if (menuIsExpanded) {
      document.body.classList.add('sidebar-expanded');
      document.body.classList.remove('sidebar-collapsed');
    } else {
      document.body.classList.remove('sidebar-expanded');
      document.body.classList.add('sidebar-collapsed');
    }
  };

  return (
    <div
      className={`sidebar ${theme} ${apiVersion?.environment} ${
        authService.canAccessBOE() || authService.canAccessBReaderBOE()
          ? 'boe'
          : authService.canAccessBOI()
            ? 'boi'
            : ''
      }`}
      {...rest}
    >
      {sidebarIsExpanded ? (
        <Box>
          <Box sx={{ mx: 2, mt: 3 }} className="d-flex align-items-center justify-content-between">
            <Box className="logo d-flex align-items-center">
              <IconButton sx={{ p: 0 }} className="logo-icone d-flex align-items-center">
                <Link to="/" id="logo" style={{ height: 34 }}>
                  <img
                    src={
                      //eslint-disable-next-line
                      process.env.PUBLIC_URL + '/logo-birdz-btech-icone.png'
                    }
                    alt=""
                  />
                </Link>
              </IconButton>
              <Box sx={{ ml: 0.5, mt: 0.5 }}>
                <Link to="/" id="logo">
                  <img
                    src={
                      //eslint-disable-next-line
                      process.env.PUBLIC_URL + '/logo-birdz-btech.png'
                    }
                    alt=""
                    className="logo-texte"
                  />
                </Link>
              </Box>
            </Box>
            <IconButton id="burger" sx={{ width: 36, height: 36 }} onClick={() => toggleBurger()}>
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{ mx: 2, mt: 3 }}
          className="d-flex flex-column align-items-center justify-content-between"
        >
          <IconButton
            id="burger"
            sx={{ width: 36, height: 36, mb: 0.5 }}
            onClick={() => toggleBurger()}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            sx={{ width: 36, height: 36, mb: 1 }}
            onClick={() => navigate('/')}
            className="logo-rotate"
          >
            <img
              width={25}
              height={25}
              src={
                //eslint-disable-next-line
                process.env.PUBLIC_URL + '/logo-birdz-btech-icone.png'
              }
              alt=""
            />
          </IconButton>
        </Box>
      )}
      {sidebarIsExpanded ? (
        <SidebarInfos apiVersion={apiVersion} sidebarIsExpanded={sidebarIsExpanded} />
      ) : (
        <Box className="w-100 d-flex align-items-center justify-content-center collapsed-item">
          <IconButton
            sx={{ width: 36, height: 36 }}
            className={`icon-button ${apiVersion?.environment}`}
          >
            {
              environnements[(apiVersion?.environment as keyof typeof environnements) || 'default']
                .icon
            }
          </IconButton>
          <SidebarInfos apiVersion={apiVersion} sidebarIsExpanded={sidebarIsExpanded} />
        </Box>
      )}

      <Box sx={{ pt: 2 }} className="sidebar-wrapper">
        <Box className="sidebar-scroll">
          {sidebarItems.filter(Boolean).map((sidebarItem) => (
            <SidebarItem
              sidebarItem={sidebarItem}
              key={sidebarItem.key}
              path={sidebarItem.key}
              collapsed={!sidebarIsExpanded}
            />
          ))}
        </Box>
      </Box>

      {authService.canAccessBOI() && (
        <ThemeSelector
          sidebarIsExpanded={sidebarIsExpanded}
          theme={theme}
          setTheme={(theme) => {
            setTheme(theme);
            localStorage.setItem('sidebarTheme', theme);
          }}
        />
      )}

      <span className="logout">
        <Tooltip title="se déconnecter" enterDelay={200}>
          <IconButton onClick={authService.logout}>
            <PowerSettingsNewIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </span>

      {authService.canAccessBOI() && (
        <span className="version">
          <Box
            onClick={() => {
              setShowUiVersion(!showUiVersion);
            }}
          >
            <Box
              sx={{ mr: 1 }}
              title="UI version"
              style={{ visibility: showUiVersion ? 'visible' : 'hidden' }}
            >
              {' '}
              UI: {appVersion} /
            </Box>
            <Box sx={{ mr: 2 }} title={`Dernier commit: ${apiVersion?.last_commit || '-'}`}>
              Version: {apiVersion?.version}
            </Box>{' '}
          </Box>
        </span>
      )}
    </div>
  );
};

export default Sidebar;
