import React, { useEffect, useState } from 'react';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import {
  Alert,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import moment from 'moment';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import { AnomaliesProps } from './types';
import axios from 'axios';

type InterventionAnomaliesProps = {
  anomalies: AnomaliesProps[] | [];
  readOnly: boolean;
  onRefresh: () => void;
};

export default function InterventionAnomalies({
  anomalies,
  readOnly,
  onRefresh
}: InterventionAnomaliesProps) {
  const [allIgnored, setAllIgnored] = useState<boolean>(false);
  const { notif, notifOptions } = useNotif();

  useEffect(() => {
    if (anomalies && anomalies.length) {
      setAllIgnored(anomalies.every((obj) => obj.status === 'IGNORED'));
    }
  }, [anomalies]);

  const displayStatus = (status: string) => {
    switch (status) {
      case 'RAISED':
        return 'Levée';
      case 'IGNORED':
        return 'Ignorée';
      default:
        return status;
    }
  };

  const ignoreOneAnomaly = (id: number) => {
    return axios
      .post(`/api/boi/extractor/anomaly/${id}/ignore/`)
      .then(() => {
        notif({
          type: 'success',
          content: 'Anomalie ignorée avec succès'
        });
        setTimeout(() => {
          onRefresh();
        }, 1500);
      })
      .catch((error) => {
        notif({
          type: 'error',
          content: `Erreur : Anomalie non ignorée. ${error.message}`
        });
      });
  };

  const ignoreAllAnomalies = async () => {
    const ids: number[] = [];

    anomalies &&
      anomalies.map((anomaly) => {
        anomaly.status !== 'IGNORED' && ids.push(anomaly.id);
      });

    const requests = ids.map((id) => {
      return axios.post(`/api/boi/extractor/anomaly/${id}/ignore/`);
    });

    await axios
      .all(requests)
      .then(() => {
        notif({
          type: 'success',
          content: 'Anomalies ignorées avec succès'
        });
        setTimeout(() => {
          onRefresh();
        }, 1500);
      })
      .catch((error) => {
        notif({
          type: 'error',
          content: `Erreur : Anomalie(s) non ignorée(s). ${error.message}`
        });
      });
  };

  return (
    <>
      {anomalies && anomalies.length !== 0 ? (
        <>
          {!readOnly && (
            <Grid container justifyContent="flex-end">
              <Button variant="outlined" onClick={() => ignoreAllAnomalies()} disabled={allIgnored}>
                Ignorer les anomalies restantes
              </Button>
            </Grid>
          )}

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, mt: 2 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow sx={{ backgroundColor: 'grey' }}>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Anomalie</TableCell>
                  <TableCell align="center">Détail</TableCell>
                  <TableCell align="center">Statut</TableCell>
                  {!readOnly && <TableCell align="center">Actions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {anomalies.map((anomaly) => (
                  <TableRow key={anomaly.id}>
                    <TableCell align="center">
                      {moment.unix(anomaly.created_at).format('DD/MM/YYYY' + ' à ' + 'HH:mm')}
                    </TableCell>
                    <TableCell align="center">{anomaly.type}</TableCell>
                    <TableCell align="center">{anomaly.details}</TableCell>
                    <TableCell align="center">{displayStatus(anomaly.status)}</TableCell>
                    {!readOnly && (
                      <TableCell align="center">
                        <Tooltip title="Ignorer">
                          <IconButton
                            disabled={anomaly.status === 'IGNORED'}
                            onClick={() => {
                              ignoreOneAnomaly(anomaly.id);
                            }}
                          >
                            <NotificationsPausedIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Grid>
          <Alert severity="info">Aucune anomalie</Alert>
        </Grid>
      )}

      <BirdzNotif options={notifOptions} />
    </>
  );
}
