import React from 'react';
import { Box } from '@mui/material';
import { environnements } from './const';
import { authService } from '../..';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { ApiVersion } from './types';

const SidebarInfos = ({
  apiVersion,
  sidebarIsExpanded
}: {
  apiVersion: ApiVersion | undefined;
  sidebarIsExpanded: boolean;
}) => {
  return (
    <Box
      fontSize={15}
      className={`environments ${!sidebarIsExpanded ? 'collapsed-item-content' : ''}`}
    >
      <Box sx={{ mb: 0.25 }} className="category">
        Environnement
      </Box>
      <Box
        className={`d-flex align-items-end justify-content-between env ${apiVersion?.environment}`}
      >
        {
          environnements[(apiVersion?.environment as keyof typeof environnements) || 'default']
            .label
        }
        {environnements[(apiVersion?.environment as keyof typeof environnements) || 'default'].icon}
      </Box>
      <Box>
        {authService.canAccessBOE() ? (
          <>
            <Box sx={{ mb: 0.25, mt: 1 }} className="category">
              Espace
            </Box>
            <Box className="d-flex align-items-end justify-content-between">
              <div>Gestionnaire</div>
              <AccountCircleIcon fontSize="small" />
            </Box>
            <Box sx={{ mb: 0.25, mt: 1 }} className="category">
              Nom
            </Box>
            <div className="subcontractor-name">{authService.user?.subcontractor__name || '-'}</div>
          </>
        ) : authService.canAccessBOI() && !authService.isAdmin() ? (
          <>
            <Box sx={{ mb: 0.25, mt: 1 }} className="category">
              Espace
            </Box>
            <Box className="d-flex align-items-end justify-content-between">
              <div>Support</div>
              <SupportAgentIcon fontSize="small" />
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default SidebarInfos;
