import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SidebarItemType } from '../useGetSidebarItems';
import { Box, IconButton } from '@mui/material';
import { getNestedKeyPath } from './const';
import SidebarItem from './SidebarItem';
import { ItemIcon } from './Item';

const CollapsedItem = ({
  isActive,
  item,
  path
}: {
  isActive: boolean;
  item: SidebarItemType;
  path: string;
}) => {
  const navigate = useNavigate();

  return (
    <Box
      className={`collapsed-item w-100 d-flex justify-content-center ${isActive ? 'active' : ''}`}
      sx={{ py: 1 }}
    >
      <IconButton
        onClick={() => {
          navigate(item.children && item?.children?.length > 0 ? getNestedKeyPath(item) : path);
        }}
        sx={{ width: 36, height: 36 }}
      >
        <ItemIcon level={0} icon={item.icon} isActive={isActive} />
      </IconButton>
      <Box sx={{ p: 2 }} className="collapsed-item-content">
        <SidebarItem sidebarItem={item} path={path} level={1} collapsed={true} />
      </Box>
    </Box>
  );
};

export default CollapsedItem;
