import React from 'react';
import { Box, IconButton, Popover, Tooltip } from '@mui/material';
import PaletteIcon from '@mui/icons-material/Palette';
import { SidebarTheme } from './types';

const ThemeSelector = ({
  theme,
  setTheme,
  sidebarIsExpanded
}: {
  theme: SidebarTheme;
  setTheme: (value: SidebarTheme) => void;
  sidebarIsExpanded: boolean;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'colors-popover' : undefined;

  const colors: { [key in SidebarTheme]: string } = {
    base: '#434a60',
    'custom-blue': '#635bff',
    'birdz-blue': '#95cde8',
    'birdz-orange': '#f7a677',
    'birdz-green': '#7ed17c'
  };

  return (
    <Box
      className="theme-selector"
      sx={{ mb: sidebarIsExpanded ? 2 : 7, ml: sidebarIsExpanded ? 6 : 1, pt: 1 }}
    >
      <Tooltip title="choix du thème" enterDelay={200}>
        <IconButton onClick={handleClick}>
          <PaletteIcon fontSize="small" sx={{ color: colors[theme] }} />
        </IconButton>
      </Tooltip>
      <Popover
        elevation={10}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: '#1a1f2c'
          }
        }}
      >
        <Box sx={{ p: 1, gap: '0.5rem' }} className="d-flex align-items-center">
          {Object.entries(colors).map(([key, value]) => (
            <Box
              key={key}
              sx={{
                width: '15px',
                height: '15px',
                backgroundColor: value,
                borderRadius: '50%',
                cursor: 'pointer',
                border: `2px solid ${theme === key ? 'white' : 'transparent'}`,
                transition: 'background-color 0.5s ease-in-out, border 0.5s ease-in-out',
                '&:hover': {
                  filter: 'brightness(1.2)'
                }
              }}
              onClick={() => {
                setTheme(key as SidebarTheme);
              }}
            />
          ))}
        </Box>
      </Popover>
    </Box>
  );
};

export default ThemeSelector;
