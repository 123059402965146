import React from 'react';
import EngineeringIcon from '@mui/icons-material/Engineering';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PublicIcon from '@mui/icons-material/Public';
import { CircularProgress } from '@mui/material';

export const environnements = {
  dev: { label: 'Développement', icon: <EngineeringIcon fontSize={'small'} /> },
  ppd: { label: 'Pré-production', icon: <HourglassTopIcon fontSize={'small'} /> },
  prd: { label: 'Production', icon: <PublicIcon fontSize={'small'} /> },
  default: { label: 'recherche en cours', icon: <CircularProgress color="info" size={15} /> }
};
