import React, { useEffect, useState } from 'react';
import {
  ListPage,
  BirdzDialog,
  useDialog,
  AppPaper,
  BirdzTitle
} from '@applications-terrains/birdz-react-library';
import { Link, useNavigate } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Grid, IconButton } from '@mui/material';
import { BirdzNotif, useNotif, SearchField } from '@applications-terrains/birdz-react-library';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { Erp } from './ErpForm';

type VersionProps = {
  value: string;
  label: string;
};

const Erps = () => {
  const endpoint = '/api/boi/extractor/erps/';
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();
  const navigate = useNavigate();

  const [results, setResults] = useState<Erp[]>([]);
  const [versionsAvailableForSelectedErp, setVersionsAvailableForSelectedErp] = useState<
    VersionProps[]
  >([]);

  useEffect(() => {
    if (results) {
      let newVersionsAvailable: any[] = [];
      newVersionsAvailable = results.map((erp) => {
        return { value: erp.version, label: erp.version };
      });
      setVersionsAvailableForSelectedErp(newVersionsAvailable);
    }
  }, [results]);

  const listFields = [
    { name: 'name', label: 'Nom', orderable: false },
    { name: 'version', label: 'Version', orderable: false },
    { name: 'description', label: 'Description', orderable: false },
    { name: 'clients_count', label: 'Nb clients', orderable: false },
    { name: 'export_formats_count', label: 'Nb formats', orderable: false }
  ];

  const actions = [
    {
      name: 'duplicate',
      label: "Dupliquer l'ERP",
      render: (erp: Erp) => {
        return (
          <IconButton
            onClick={async () => {
              const response: AxiosResponse<Erp> = await axios.get(`${endpoint}${erp.id}/`);
              const newErp: Partial<Erp> = response.data;
              newErp.name = `${newErp.name} (copie)`;
              delete newErp.id;
              axios.post(endpoint, newErp).then(() => {
                notif({
                  content: "L'ERP a été dupliqué avec succès",
                  type: 'success'
                });
              });
              setTimeout(() => {
                navigate(0);
              }, 2000);
            }}
          >
            <ContentCopy fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'edit',
      label: "Modifier l'ERP",
      render: (group: any) => {
        return (
          <IconButton component={Link} to={`/bconnect/extractor/admin/erps/edit/${group.id}`}>
            <EditIcon fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'delete-client',
      label: "Supprimer l'ERP",
      render: (model: any) => {
        return (
          <IconButton
            onClick={() => {
              confirmDialog({
                title: "Supprimer l'ERP",
                content: 'Êtes-vous sûr de vouloir supprimer cet ERP?',
                onValidate: () => {
                  axios.delete(`${endpoint}${model.id}/`).then(
                    () => {
                      notif({
                        content: "L'ERP a été supprimé avec succès",
                        type: 'success'
                      });
                      document.location.reload();
                    },
                    () => {
                      notif({
                        content: 'Une erreur est survenue lors de la suppression',
                        type: 'error'
                      });
                    }
                  );
                  closeDialog();
                },
                onCancel: () => {
                  closeDialog();
                }
              });
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        );
      }
    }
  ];

  const searchFields: SearchField[] = [
    {
      name: 'name',
      label: 'Nom',
      options: {
        identifier: 'name',
        source: endpoint
      }
    },
    {
      name: 'version',
      label: 'Version',
      type: 'select',
      options: {
        label: 'version',
        identifier: 'version',
        values: versionsAvailableForSelectedErp
      }
    }
  ];

  return (
    <AppPaper>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <BirdzTitle>Liste des ERPs</BirdzTitle>
        </Grid>
        <Grid item>
          <Link to="/bconnect/extractor/admin/erps/add">
            <Button variant="contained" sx={{ mt: 3 }}>
              <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un ERP
            </Button>
          </Link>
        </Grid>
      </Grid>

      <ListPage
        endpoint={endpoint}
        fields={listFields}
        actions={actions}
        searchFields={searchFields}
        onLoadItems={(items: any) => {
          setResults(items);
        }}
      />
      <BirdzDialog options={dialogOptions} />
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
};

export default Erps;
