import React, { useState } from 'react';
import {
  ListPage,
  BirdzDialog,
  useDialog,
  AppPaper,
  BirdzTitle
} from '@applications-terrains/birdz-react-library';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  Typography
} from '@mui/material';
import { BirdzNotif, useNotif, SearchField } from '@applications-terrains/birdz-react-library';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import { useFetchContext } from '../../../contexts/fetchContext';
import Delete from '@mui/icons-material/Delete';
import DisplayConfigurationParameters from './DisplayConfigParameters';
import { SiGooglesheets } from 'react-icons/si';
import { getOption } from '../../Contracts/hooks/const';
import { Contract } from '../../Contracts/ContractsDropdown';
import { Configuration } from './types';

const ConfigurationsList = () => {
  const endpoint = '/api/bstock/configurations/';
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();
  const navigate = useNavigate();
  const fetchContext = useFetchContext();

  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const listFields = [
    { name: 'id', label: '#' },
    { name: 'name', label: 'Configuration' },
    {
      name: 'created_at',
      label: 'Date de création',
      transform: (date: number) => {
        return (date && moment.unix(date).format('DD/MM/YYYY à HH:mm:ss')) || '-';
      }
    },
    {
      name: 'parameters',
      label: 'Paramètres',
      transform: (_: any, data: { [key: string]: any }) => {
        const { protocol_name, dst, time_index, autoconnect } = data;
        return (
          <DisplayConfigurationParameters
            protocol_name={protocol_name}
            dst={dst}
            time_index={time_index}
            autoconnect={autoconnect}
          />
        );
      }
    },
    {
      name: 'contracts_data',
      label: 'Clients autorisés',
      transform: (values: Contract[], row: Configuration) => {
        const clients = values.map((client: any) => {
          return client.client;
        });
        if (row.select_all_contracts) {
          return 'Tous';
        }
        if (values.length === 0) {
          return 'Aucun';
        }
        if (values.length && values.length < 2) {
          return clients;
        } else {
          const clientsNumber = values.length - 1;
          return (
            <>
              {clients[0]} et{' '}
              <Typography
                component="span"
                onClick={() => {
                  handleOpen();
                }}
                style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
              >
                {clientsNumber} autre{clientsNumber > 1 && 's'}
              </Typography>
              <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                onClose={handleClose}
                open={open}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
              >
                {' '}
                <DialogTitle>Clients autorisés</DialogTitle>
                <DialogContent dividers>
                  <List sx={{ pt: 0 }}>
                    {values.map((contract: Contract) => {
                      const label = getOption(contract).optionLabel;
                      return (
                        <Box sx={{ mb: 1 }} key={contract.client}>
                          {label}
                        </Box>
                      );
                    })}
                  </List>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>
              </Dialog>
            </>
          );
        }
      }
    },
    { name: 'created_by_email', label: 'Créé par' }
  ];

  const actions = [
    {
      name: 'edit',
      label: 'Modifier la configuration',
      render: (config: any) => {
        return (
          <IconButton onClick={() => navigate('/bstock/configurations/edit/' + config.id)}>
            <EditIcon fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'delete-configuration',
      label: 'Supprimer la configuration',
      render: (model: { id: number }) => {
        return (
          <IconButton
            onClick={() => {
              confirmDialog({
                title: 'Supprimer la configuration',
                content: 'Êtes-vous sûr de vouloir supprimer cette configuration ?',
                onValidate: () => {
                  axios.delete(`${endpoint}${model.id}/`).then(
                    () => {
                      notif({
                        content: 'La configuration a été supprimée avec succès',
                        type: 'success'
                      });
                      fetchContext.toggleNeedToFetch(true);
                    },
                    () => {
                      notif({
                        content: 'Une erreur est survenue lors de la suppression',
                        type: 'error'
                      });
                    }
                  );
                  closeDialog();
                },
                onCancel: () => {
                  closeDialog();
                }
              });
            }}
          >
            <Delete fontSize="small" />
          </IconButton>
        );
      }
    }
  ];

  const searchFields: SearchField[] = [
    {
      name: 'name',
      label: 'Nom de la configuration',
      type: 'text',
      size: 12
    }
  ];

  return (
    <AppPaper>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <BirdzTitle>Configurations</BirdzTitle>
        </Grid>
        <Grid item>
          <Link to="/bstock/configurations/add">
            <Button variant="contained" sx={{ mt: 3 }}>
              <AddCircleIcon sx={{ mr: 1 }} /> Ajouter une configuration
            </Button>
          </Link>
        </Grid>
      </Grid>

      {!fetchContext.needToFetch && (
        <ListPage
          endpoint={endpoint}
          fields={listFields}
          actions={actions}
          searchFields={searchFields}
          exportButtons={[
            {
              name: 'Exporter en gsheet',
              fileName: 'b_stock_configurations',
              type: 'csv',
              icon: <SiGooglesheets style={{ marginRight: '0.5rem' }} />,
              requestEndpoint: '/api/bstock/configurations/export/',
              keepQueryParams: false,
              onSuccess: () => {
                notif({
                  type: 'success',
                  content: 'Export réussi'
                });
              }
            }
          ]}
        />
      )}

      <BirdzDialog options={dialogOptions} />
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
};

export default ConfigurationsList;
