import React from 'react';
import Home from './components/Home/Home';
import Login from './components/Login/Login';
import DashboardLayout from './components/DashboardLayout/DashboardLayout';
import Diagnostics from './components/CR/Diagnostics/Diagnostics';
import DMSManager from './components/DMS/DMSManager';
import Users from './components/Admin/Users/Users';
import Groups from './components/Admin/Groups/Groups';
import { Outlet } from 'react-router-dom';
import Sections from './components/Admin/Sections/Sections';
import Logs from './components/Admin/Log/Logs';
import Subcontractors from './components/Admin/Subcontractor/Subcontractor';
import Contracts from './components/Admin/Contract/Contracts';
import Messages from './components/Admin/Messages/Messages';
import CrCases from './components/CR/Cases/CrCases';
import CrCaseForm from './components/CR/Cases/CrCaseForm';
import NotFound from './components/NotFound/NotFound';
import SamplePage from './components/SamplePage';
import ParamsList from './components/Admin/Params/ParamsList';
import Planning from './components/Planning/Planning';
import Informations from './components/Informations/Informations';
import MonthlyStatementListing from './components/MonthlyStatement/MonthlyStatementListing';
import SubcontractorBOE from './components/Admin/Subcontractor/SubcontractorBOE';
import CallsListing from './components/Calls/CallsListing';
import SearchCallsForm from './components/Calls/SearchCallsForm';
import { CallbackLogin, PrivateOutlet } from '@applications-terrains/birdz-react-library';
import InformationDetails from './components/Informations/InformationDetails';
import SearchDevices from './components/CR/SearchDevices/SearchDevices';
import InterventionForm from './components/CR/Interventions/InterventionForm';
import Interventions from './components/CR/Interventions/Interventions';
import MonthlyStatementDetails from './components/MonthlyStatement/MonthlyStatementDetails';
import SubcontractorMonthlyStatementDetails from './components/MonthlyStatement/SubcontractorMonthlyStatementDetails';
import MrCases from './components/MR/Cases/MrCases';
import MrCaseForm from './components/MR/Cases/MrCaseForm';
import MrCaseDiagnostic from './components/MR/Cases/MrCaseDiagnostic';
import MrCaseInterventionDetails from './components/MR/Cases/MrCaseInterventionDetails';
import CrStatistics from './components/CR/Statistics/CrStatistics';
import PasswordReset from './components/PasswordReset/PasswordReset';
import HomePage from './components/BConnect/HomePage';
import Clients from './components/Extractor/Clients/Clients';
import ClientForm from './components/Extractor/Clients/ClientsForm';
import Erps from './components/Extractor/Erps/Erps';
import ErpForm from './components/Extractor/Erps/ErpForm';
import AnomalyProfiles from './components/Extractor/AnomalyProfiles/AnomalyProfiles';
import ExportFormats from './components/Extractor/ExportFormats/ExportFormats';
import ExportFormatForm from './components/Extractor/ExportFormats/ExportFormatForm';
import Dashboard from './components/BConnect/Dashboard/Dashboard';
import ExportFormatSettings from './components/Extractor/ExportFormats/ExportFormatSettings';
import LicencesList from './components/BConnect/Dashboard/LicencesList';
import Jobs from './components/Extractor/Jobs/Jobs';
import JobForm from './components/Extractor/Jobs/JobForm';
import JobsExecutions from './components/Extractor/Jobs/JobsExecutions';
import NotationsList from './components/BConnect/Dashboard/NotationsList';
import BconnectUsers from './components/BConnect/BconnectUsers';
import CorrespondanceDetails from './components/Extractor/Correspondances/details/CorrespondanceDetails';
import ExportDetail from './components/Extractor/Jobs/ExportDetails';
import ExtractorInterventions from './components/Extractor/Interventions/Interventions';
import ExtractorInterventionForm from './components/Extractor/Interventions/InterventionForm';
import ExtractorDashboard from './components/Extractor/Interventions/Dashboard';
import CorrespondancesList from './components/Extractor/Correspondances/list/CorrespondancesList';
import SessionsList from './components/BStock/Sessions/SessionsList';
import ConfigurationsList from './components/BStock/Configurations/ConfigurationsList';
import ConfigurationDetail from './components/BStock/Configurations/ConfigurationDetail';
import SessionDetail from './components/BStock/Sessions/SessionDetail';
import InterventionDetails from './components/BConnect/Interventions/InterventionDetails';
import ExportsList from './components/BReader/BOI/Exports/ExportsList';
import BReaderExportDetail from './components/BReader/BOI/Exports/ExportDetail';
import ReadersList from './components/BReader/BOE/Readers/ReadersList';
import ToursList from './components/BReader/BOI/Tours/ToursList';
import ToursListBOE from './components/BReader/BOE/Tours/ToursListBOE';
import TourFormBOE from './components/BReader/BOE/Tours/TourForm/TourFormBOE';
import TourDetails from './components/BReader/BOE/Tours/TourForm/TourDetails';
import BReaderClientsList from './components/Admin/BReaderClients/BReaderClientsList';
import BReaderClientForm from './components/Admin/BReaderClients/BReaderClientForm';
import { GlobalContextProvider } from './contexts/globalContext';
import ExtractorInterventionsWrapper from './components/Extractor/Interventions/ExtractorInterventionsWrapper';

export type RouteProps = {
  path: string;
  element?: any;
  label?: string;
  permissions?: string[];
  children?: RouteProps[];
};

const routes: RouteProps[] = [
  {
    path: '/callback',
    element: <CallbackLogin />
  },
  {
    path: '/auth',
    element: <Login />
  },
  {
    path: '/password-reset',
    element: <PasswordReset />
  },
  {
    path: '/',
    element: (
      <GlobalContextProvider>
        <DashboardLayout component={<PrivateOutlet />} />
      </GlobalContextProvider>
    ),
    children: [
      {
        path: '',
        element: <Home />,
        label: 'Accueil'
      },
      {
        path: 'bconnect',
        label: 'B-Connect',
        permissions: ['BCONNECT_MENU'],
        children: [
          {
            path: 'homepage',
            label: 'Accueil',
            element: <HomePage />
          },
          {
            path: 'dashboard',
            label: 'Dashboard',
            children: [
              {
                path: 'overview',
                label: "Vue d'ensemble",
                element: <Dashboard />
              },
              {
                path: 'licences',
                label: 'Licences',
                element: <LicencesList />
              },
              {
                path: 'notations',
                label: 'Notations',
                element: <NotationsList />
              }
            ]
          },
          {
            path: 'users',
            children: [
              {
                path: '',
                element: <BconnectUsers action="list" />,
                label: 'Utilisateurs'
              },
              {
                path: 'edit/:id',
                element: <BconnectUsers action="edit" />,
                label: 'Éditer'
              }
            ]
          },
          {
            path: 'interventions',
            label: 'Suivi des interventions',
            element: <ExtractorInterventionsWrapper />,
            children: [
              {
                path: 'dashboard',
                element: <ExtractorDashboard />,
                label: 'Tableau de bord'
              },
              {
                path: '',
                element: <ExtractorInterventions target={'pending'} />,
                label: 'Liste des interventions'
              },
              {
                path: 'details/:id',
                element: <InterventionDetails />,
                label: 'Détail'
              }
            ]
          },
          {
            path: 'extractor',
            label: 'Extract',
            children: [
              {
                path: 'interventions',
                label: 'Interventions',
                element: <ExtractorInterventionsWrapper />,
                children: [
                  {
                    path: '',
                    element: <ExtractorInterventions target={'all'} />,
                    label: 'Interventions terminées'
                  },
                  {
                    path: 'edit/:id',
                    element: <ExtractorInterventionForm readOnly={false} />,
                    label: 'Éditer'
                  },
                  {
                    path: 'view/:id',
                    element: <ExtractorInterventionForm readOnly={true} />,
                    label: "Détails de l'intervention"
                  }
                ]
              },
              {
                path: 'jobs',
                label: 'Jobs',
                children: [
                  {
                    path: '',
                    element: <Jobs />,
                    label: 'Liste des jobs'
                  },
                  {
                    path: 'add',
                    element: <JobForm />,
                    label: 'Ajouter'
                  },
                  {
                    path: 'edit/:id',
                    element: <JobForm />,
                    label: 'Éditer'
                  }
                ]
              },
              {
                path: 'executions',
                label: 'Historique des exports',
                children: [
                  {
                    path: '',
                    element: <JobsExecutions />,
                    label: 'Liste des exports'
                  },
                  {
                    path: 'details/:id',
                    element: <ExportDetail />,
                    label: 'Details'
                  }
                ]
              },
              {
                path: 'admin',
                children: [
                  {
                    path: 'clients',
                    children: [
                      {
                        path: '',
                        element: <Clients />,
                        label: 'Clients'
                      },
                      {
                        path: 'add',
                        element: <ClientForm />,
                        label: 'Ajouter'
                      },
                      {
                        path: 'edit/:id',
                        element: <ClientForm />,
                        label: 'Éditer'
                      }
                    ]
                  },
                  {
                    path: 'erps',
                    children: [
                      {
                        path: '',
                        element: <Erps />,
                        label: 'ERPs'
                      },
                      {
                        path: 'add',
                        element: <ErpForm />,
                        label: 'Ajouter'
                      },
                      {
                        path: 'edit/:id',
                        element: <ErpForm />,
                        label: 'Éditer'
                      }
                    ]
                  },
                  {
                    path: 'anomaly-profiles',
                    children: [
                      {
                        path: '',
                        element: <AnomalyProfiles action="list" />,
                        label: "Profils d'anomalies"
                      },
                      {
                        path: 'add',
                        element: <AnomalyProfiles action="add" />,
                        label: 'Ajouter'
                      },
                      {
                        path: 'edit/:id',
                        element: <AnomalyProfiles action="edit" />,
                        label: 'Éditer'
                      }
                    ]
                  },
                  {
                    path: 'export-formats',
                    children: [
                      {
                        path: '',
                        element: <ExportFormats />,
                        label: "Formats d'export"
                      },
                      {
                        path: 'add',
                        element: <ExportFormatForm />,
                        label: 'Ajouter'
                      },
                      {
                        path: 'edit/:id',
                        element: <ExportFormatForm />,
                        label: 'Éditer'
                      },
                      {
                        path: 'settings/:id',
                        element: <ExportFormatSettings />,
                        label: 'Paramètres'
                      }
                    ]
                  },
                  {
                    path: 'correspondances',
                    children: [
                      {
                        path: '',
                        element: <CorrespondancesList />,
                        label: 'Correspondances'
                      },
                      {
                        path: 'details/:id',
                        element: <CorrespondanceDetails />,
                        label: 'Détail'
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'bstock',
        label: 'B-Stock',
        permissions: ['BSTOCK_MENU'],
        children: [
          {
            path: 'sessions',
            children: [
              {
                path: '',
                label: 'Sessions',
                element: <SessionsList />
              },
              {
                path: 'details/:id',
                label: 'Detail',
                element: <SessionDetail />
              }
            ]
          },
          {
            path: 'configurations',
            children: [
              {
                path: '',
                label: 'Configurations',
                element: <ConfigurationsList />
              },
              {
                path: 'add',
                label: 'Detail',
                element: <ConfigurationDetail />
              },
              {
                path: 'edit/:id',
                label: 'Detail',
                element: <ConfigurationDetail />
              }
            ]
          }
        ]
      },
      {
        path: 'breader',
        label: 'B-Reader',
        permissions: ['BREADER_MENU', 'BOI'],
        children: [
          {
            path: 'exports',
            children: [
              {
                path: '',
                label: 'Exports',
                element: <ExportsList />
              },
              {
                path: 'add',
                label: 'Ajout',
                element: <BReaderExportDetail />
              },
              {
                path: 'details/:id',
                label: 'Detail',
                element: <BReaderExportDetail />
              }
            ]
          },
          {
            path: 'tours',
            children: [
              {
                path: '',
                label: 'Tournées',
                element: <ToursList />
              },
              {
                path: 'details/:id',
                element: <TourDetails />,
                label: 'Détails'
              }
            ]
          },
          {
            path: 'admin',
            children: [
              {
                path: 'clients',
                label: 'Clients',
                element: <BReaderClientsList />
              },
              {
                path: 'clients/add',
                label: 'Ajout',
                element: <BReaderClientForm action="add" />
              },
              {
                path: 'clients/edit/:id',
                label: 'Détails',
                element: <BReaderClientForm action="edit" />
              }
            ]
          }
        ]
      },
      {
        path: 'boi',
        permissions: ['BOI'],
        children: [
          {
            path: 'calls',
            label: 'Gestion des appels',
            permissions: ['CALLS_MENU'],
            children: [
              {
                path: 'new',
                label: 'Réception',
                element: <SearchCallsForm />
              },
              {
                path: 'listing',
                label: 'Listing',
                permissions: ['CALLS_MENU_PAGE'],
                element: <CallsListing />
              }
            ]
          },
          {
            path: 'cr',
            label: 'Concentrateurs',
            permissions: ['CR_MENU'],
            children: [
              {
                path: 'diagnostics',
                permissions: ['CR_MENU_DIAG'],
                children: [
                  {
                    path: '',
                    element: <SearchDevices />,
                    label: 'Diagnostics'
                  },
                  {
                    path: 'details/:moduleAddress',
                    element: <Diagnostics />,
                    label: 'Details'
                  }
                ]
              },
              {
                path: 'cases',
                label: 'Dossiers',
                permissions: ['CR_MENU_DOSSIER'],
                children: [
                  {
                    path: '',
                    element: <CrCases />,
                    label: 'Dossiers'
                  },
                  {
                    path: 'edit/:id',
                    element: <CrCaseForm />,
                    label: 'Détail'
                  }
                ]
              },
              {
                path: 'interventions',
                label: 'Interventions',
                permissions: ['CR_MENU_INTER'],
                children: [
                  {
                    path: '',
                    element: <Interventions />,
                    label: 'Interventions associées aux dossiers'
                  },
                  {
                    path: 'edit/:id',
                    element: <InterventionForm />,
                    label: 'Retour technicien'
                  }
                ]
              },
              {
                path: 'statistics',
                label: 'Tableaux de bords',
                permissions: ['CR_MENU_TAB_CRP'],
                children: [
                  {
                    path: 'cr+',
                    element: <CrStatistics />,
                    label: 'Rapport maintenance CR+'
                  }
                ]
              }
            ]
          },
          {
            path: 'mr',
            label: 'Module de répétition',
            permissions: ['MR_MENU'],
            children: [
              {
                path: 'cases',
                label: 'Dossiers',
                permissions: ['MR_MENU_DOSSIER'],
                children: [
                  {
                    path: '',
                    element: <MrCases />,
                    label: 'Dossiers'
                  },
                  {
                    path: 'edit/:id',
                    element: <MrCaseForm />,
                    label: 'Détail'
                  },
                  {
                    path: ':caseId/intervention/:id',
                    label: 'Intervention détails',
                    element: <MrCaseInterventionDetails />
                  }
                ]
              },
              {
                path: 'diagnostics',
                element: <MrCaseDiagnostic />,
                label: 'Diagnostics'
              }
            ]
          },
          {
            path: 'dms',
            permissions: ['GED_MENU'],
            label: 'Gestion Electronique de Documents',
            children: [
              {
                path: '',
                element: <DMSManager />
              },
              {
                path: ':driveName/:driveId',
                element: <DMSManager />
              }
            ]
          },
          {
            path: 'informations',
            label: 'Informations',
            permissions: ['INFO_CMP'],
            children: [
              {
                path: '',
                element: <Informations />,
                label: 'Informations complémentaires'
              },
              {
                path: 'details/:id',
                element: <InformationDetails />,
                label: 'Détails'
              }
            ]
          },
          {
            path: 'monthly-statement',
            label: 'Relevés mensuels',
            permissions: ['RELEVE_MENU'],
            children: [
              {
                path: '',
                element: <MonthlyStatementListing />,
                label: 'Relevés mensuels'
              },
              {
                path: 'edit/:id',
                element: <MonthlyStatementDetails readOnly={false} />,
                permissions: ['RELEVE_MENU_REC_RES'],
                label: 'Éditer'
              },
              {
                path: 'view/:id',
                element: <MonthlyStatementDetails readOnly={true} />,
                label: 'Voir'
              }
            ]
          },
          {
            path: 'administration',
            label: 'Administration',
            permissions: ['ADMIN_MENU'],
            children: [
              {
                path: 'users',
                permissions: ['ADMIN_MENU_USER'],
                children: [
                  {
                    path: '',
                    element: <Users action="list" />,
                    label: 'Utilisateurs'
                  },
                  {
                    path: 'create',
                    element: <Users action="add" />,
                    label: 'Ajouter'
                  },
                  {
                    path: 'edit/:id',
                    element: <Users action="edit" />,
                    label: 'Éditer'
                  }
                ]
              },
              {
                path: 'groups',
                permissions: ['ADMIN_MENU_GROUP'],
                children: [
                  {
                    path: '',
                    element: <Groups action="list" />,
                    label: 'Groupes'
                  },
                  {
                    path: 'add',
                    element: <Groups action="add" />,
                    label: 'Ajouter'
                  },
                  {
                    path: 'edit/:id',
                    element: <Groups action="edit" />,
                    label: 'Éditer'
                  }
                ]
              },
              {
                path: 'sections',
                label: 'Sections',
                permissions: ['ADMIN_MENU_SECTION'],
                children: [
                  {
                    path: 'add',
                    element: <Sections action="add" />,
                    label: 'Ajouter'
                  },
                  {
                    path: 'edit/:id',
                    element: <Sections action="edit" />,
                    label: 'Éditer'
                  },
                  {
                    path: '',
                    element: <Sections action="list" />,
                    label: 'Sections'
                  }
                ]
              },
              {
                path: 'subcontractors',
                label: 'Sous-traitants',
                permissions: ['ADMIN_MENU_ST'],
                children: [
                  {
                    path: '',
                    element: <Subcontractors action="list" />,
                    label: 'Liste'
                  },
                  {
                    path: 'create',
                    element: <Subcontractors action="add" />,
                    label: 'Ajouter'
                  },
                  {
                    path: 'edit/:id',
                    element: <Subcontractors action="edit" />,
                    label: 'Éditer'
                  }
                ]
              },
              {
                path: 'messages',
                permissions: ['ADMIN_MESSAGE'],
                children: [
                  {
                    path: '',
                    element: <Messages action="list" />,
                    label: 'Messages'
                  },
                  {
                    path: 'add',
                    element: <Messages action="add" />,
                    label: 'Ajouter'
                  },
                  {
                    path: 'edit/:id',
                    element: <Messages action="edit" />,
                    label: 'Éditer'
                  }
                ]
              },
              {
                path: 'contracts',
                element: <Contracts action="list" />,
                label: 'Contracts'
              },
              {
                path: 'logs',
                element: <Logs action="list" />,
                permissions: ['ADMIN_LOG'],
                label: 'Logs'
              },
              {
                path: 'tables',
                label: 'Tables techniques',
                children: [
                  {
                    path: '',
                    element: <ParamsList />,
                    label: 'Tables techniques'
                  },
                  {
                    path: ':param',
                    element: <ParamsList />,
                    label: 'Paramètre'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'breader',
        label: 'B-Reader',
        permissions: ['BOE_BREADER'],
        children: [
          {
            path: 'readers',
            children: [
              {
                path: 'list',
                label: 'Releveurs',
                element: <ReadersList />
              },
              {
                path: 'add',
                label: 'Detail',
                element: <ReadersList />
              },
              {
                path: 'edit/:id',
                label: 'Detail',
                element: <ReadersList />
              }
            ]
          },
          {
            path: 'tours',
            children: [
              {
                path: '',
                label: 'Tournées',
                element: <ToursListBOE />
              },
              {
                path: 'add',
                label: 'Nouvelle tournée',
                element: <TourFormBOE />
              },
              {
                path: 'details/:id',
                element: <TourDetails />,
                label: 'Détails'
              }
            ]
          }
        ]
      },
      {
        path: 'boe',
        permissions: ['BOE'],
        children: [
          {
            path: 'cr',
            label: 'Concentrateurs',
            permissions: ['CR_MENU'],
            children: [
              {
                path: 'planning',
                element: <Planning />,
                label: 'Planning'
              },
              {
                path: 'interventions',
                label: 'Interventions',
                children: [
                  {
                    path: '',
                    element: <Interventions />,
                    label: 'Liste'
                  },
                  {
                    path: 'details/:id',
                    element: <InterventionForm />,
                    label: 'Détails'
                  }
                ]
              }
            ]
          },
          {
            path: 'dms',
            element: <DMSManager readOnly={true} />,
            permissions: ['GED_MENU'],
            label: 'Gestion Electronique de Documents'
          },
          {
            path: 'informations',
            label: 'Informations',
            permissions: ['BOE'],
            children: [
              {
                path: '',
                element: <Informations />,
                label: 'Informations complémentaires'
              },
              {
                path: 'details/:id',
                element: <InformationDetails />,
                label: 'Détails'
              }
            ]
          },
          {
            path: 'monthly-statement',
            label: 'Relevés mensuels',
            element: <Outlet />,
            permissions: ['RELEVE_MENU'],
            children: [
              {
                path: '',
                element: <MonthlyStatementListing />,
                label: 'Relevés mensuels'
              },
              {
                path: 'add/:year/:month',
                element: <SubcontractorMonthlyStatementDetails readOnly={false} />,
                permissions: ['RELEVE_MENU_CREATE'],
                label: 'Ajouter'
              },
              {
                path: 'edit/:id',
                element: <SubcontractorMonthlyStatementDetails readOnly={false} />,
                label: 'Éditer'
              },
              {
                path: 'view/:id',
                element: <SubcontractorMonthlyStatementDetails readOnly={true} />,
                label: 'Voir'
              }
            ]
          },
          {
            path: 'administration',
            permissions: ['ADMIN_MENU'],
            children: [
              {
                path: 'subcontractors',
                element: <SubcontractorBOE />,
                label: 'Sous-traitant'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'sample-page',
    element: <DashboardLayout component={<SamplePage />} />
  },
  {
    path: '*',
    element: <DashboardLayout component={<NotFound />} />
  }
];

export default routes;
