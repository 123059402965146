import * as Yup from 'yup';

type FieldConfig = {
  type: 'string' | 'number' | 'date';
  required: boolean;
};

export type FieldsConfig = {
  [key: string]: FieldConfig;
};

export const generateValidationSchema = (config: FieldsConfig): Yup.ObjectSchema<any> => {
  const shape: Record<string, Yup.AnySchema> = {};

  Object.entries(config).forEach(([field, { type, required }]) => {
    let validator: Yup.AnySchema;

    switch (type) {
      case 'string':
        validator = Yup.string();
        break;
      case 'number':
        validator = Yup.number();
        break;
      case 'date':
        validator = Yup.date();
        break;
      default:
        throw new Error(`Unsupported field type: ${type}`);
    }

    if (required) {
      validator = validator.required('Champ obligatoire');
    }

    shape[field] = validator;
  });

  return Yup.object().shape(shape);
};
