import { Formik } from 'formik';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { CrIntervention, CrInterventionEvent } from './Types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  Grid,
  Stack,
  Tooltip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CrInterventionEvents from './CrInterventionEvents';
import axios from 'axios';
import { InterventionEventsHelper } from '../utils/EventsHelper';
import {
  FormObserver,
  InputField,
  SelectField,
  TimePickerField
} from '@applications-terrains/birdz-react-library';
import {
  formatToSelectOptions,
  useGetFailureCodes,
  useGetInterventionEvents,
  useGetInterventionStatuses,
  useGetSubcontractors
} from '../../../hooks/datarefs';
import { FieldsConfig, generateValidationSchema } from './utils';

interface CrInterventionFormProps {
  intervention: any;
  isSubmitting: boolean;
  onInterventionChange(intervention: CrIntervention): void;
  onInterventionEventsChange(eventsToSave: CrInterventionEvent[]): void;
  onError(errors: any): void;
  onClickCreateIntervention(): void;
  canCreateIntervention?: boolean;
  readOnly?: boolean;
  setFormHasError: (value: boolean) => void;
}

const fieldsConfig = {
  subcontractor: { type: 'number', required: true },
  status: { type: 'number', required: true },
  observation: { type: 'string', required: true },
  specific_instructions: { type: 'string', required: true },
  failure_code: { type: 'number', required: true },
  intervention_max_date: { type: 'date', required: true }
};

const validationSchema = generateValidationSchema(fieldsConfig as FieldsConfig);

const CrInterventionForm = forwardRef<HTMLDivElement, CrInterventionFormProps>(
  (
    {
      intervention,
      isSubmitting,
      onInterventionChange,
      onInterventionEventsChange,
      onError,
      onClickCreateIntervention,
      canCreateIntervention = true,
      readOnly = false,
      setFormHasError
    },
    ref
  ) => {
    const { data: subcontractors } = useGetSubcontractors();
    const { data: interventionEvents } = useGetInterventionEvents();
    const { data: interventionStatuses } = useGetInterventionStatuses();
    const { data: failureCodes } = useGetFailureCodes();

    const [formValues, setFormValues] = useState<Partial<CrIntervention>>({});
    const [technicians, setTechnicians] = useState<any>([]);
    const [eventsToSave, setEventsToSave] = useState<CrInterventionEvent[]>([]);
    const [events, setEvents] = useState<CrInterventionEvent[]>([]);
    const [hasError, setHasError] = useState(false);
    const [interventionIsReadOnly, setInterventionIsReadOnly] = useState(false);

    let resetFormik = () => {
      return;
    }; // Hack, because form reinitilize not working

    useEffect(() => {
      resetFormik();
      const newFormValues = Object.assign({}, intervention);
      if (!newFormValues?.status && interventionStatuses && interventionStatuses.length > 0) {
        newFormValues.status = interventionStatuses[0].id;
      }

      setFormValues(newFormValues);
      setEvents(newFormValues.events_history ?? []);
      setEventsToSave([]);
    }, [intervention]);

    useEffect(() => {
      setInterventionIsReadOnly(
        readOnly || InterventionEventsHelper.hasClosedEvent(intervention?.events_history)
      );
    }, [readOnly, intervention]);

    const onSubcontractorChange = (subcontractorId: number | undefined) => {
      if (!subcontractorId) return;
      const foundSubcontractor = subcontractors?.find(
        (subcontractor: any) => subcontractor.id === subcontractorId
      );
      if (foundSubcontractor) {
        axios
          .get('/api/boi/subcontractors/technicians/?subcontractor=' + subcontractorId)
          .then((response: any) => {
            const data = response.data.results;
            setTechnicians(
              data.map((result: any) => {
                return {
                  label: result.user__name,
                  value: result.user
                };
              })
            );
          });
        return foundSubcontractor;
      } else {
        return null;
      }
    };

    const watchFormikValues = useCallback((intervention: any, errors: any) => {
      const formIsOnError = Object.keys(errors).length > 0;
      const formIsSetted = Object.keys(intervention)?.length > 1; // > 1 because, 'status' field is set by default

      if (!formIsOnError && formIsSetted) {
        onInterventionChange(intervention);
        setHasError(false);
      } else if (formIsOnError) {
        setHasError(true);
        onError(errors);
      } else {
        setHasError(false);
      }
      //eslint-disable-next-line
    }, []);

    return (
      <Accordion
        ref={ref}
        variant="outlined"
        square
        sx={{ mt: 2, borderColor: isSubmitting && hasError ? 'red' : null }}
        defaultExpanded={true}
        disableGutters={true}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <h3>
            {intervention?.id && `Visualiser l'intervention #${intervention?.id}`}
            {!intervention?.id && canCreateIntervention && `Associer une intervention`}
          </h3>
          <Grid item xs={12} md={6}>
            <Tooltip
              id="interventions_not_closed"
              title={
                canCreateIntervention === false
                  ? "Vous ne pouvez pas créer d'intervention, car les interventions existantes ne sont pas toutes closes"
                  : undefined
              }
            >
              <span>
                <Button
                  id="add_new_intervention"
                  size="small"
                  variant="outlined"
                  sx={{ height: '30px', marginLeft: 2 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onClickCreateIntervention();
                  }}
                  disabled={canCreateIntervention === false}
                >
                  Associer une nouvelle intervention
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Formik
            enableReinitialize={true}
            initialValues={formValues}
            onSubmit={() => {
              return;
            }}
            validationSchema={validationSchema}
          >
            {({ errors, setFieldValue, values, resetForm }) => {
              // workaround because errors is not updated if inner form is not touched
              // so we manually make the comparison on mount and send the value to parent
              // TODO : full refactoring or even rebuild of the form with unique state and get rid of things like eslint-disable
              const errs: { [key: string]: string } = {};
              Object.entries(fieldsConfig).forEach(([key, value]) => {
                if (value.required && !(key in values)) errs[key] = 'Champ obligatoire';
              });
              resetFormik = resetForm;
              const errorsToSend =
                errors && Object.keys(errors).length === 0 && Object.keys(errs).length > 0
                  ? errs
                  : errors;
              setFormHasError(Object.keys(errorsToSend).length > 0);

              return (
                <>
                  <FormObserver onValueChange={watchFormikValues} />
                  <Grid container sx={{ mb: 2 }}>
                    <Grid item xs={12} md={6}>
                      <FormControl sx={{ width: 400 }}>
                        <SelectField
                          id="subcontractor"
                          onChange={(e: any, selectedSubcontractor: any) => {
                            const subcontractor = onSubcontractorChange(
                              selectedSubcontractor?.value
                            );
                            setFieldValue('subcontractor', selectedSubcontractor?.value);
                            setFieldValue('main_contact__email', subcontractor?.main_contact);
                          }}
                          name="subcontractor"
                          label="Sous-traitant *"
                          options={subcontractors?.map((subcontractor) => {
                            return {
                              label: subcontractor.name,
                              value: subcontractor.id
                            };
                          })}
                          errors={errors}
                          disabled={interventionIsReadOnly === true}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl sx={{ width: '400px' }}>
                        <SelectField
                          id="status"
                          name="status"
                          label="Statut / État"
                          options={formatToSelectOptions(interventionStatuses)}
                          disabled
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mb: 2 }}>
                    <Grid item xs={12} md={6}>
                      {values?.subcontractor && (
                        <>Contact principal : {values?.main_contact__email || 'aucun'}</>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container sx={{ mb: 2 }}>
                    {values?.subcontractor &&
                    InterventionEventsHelper.hasFeedback(values?.events_history) ? (
                      <Grid item xs={12} md={6}>
                        <FormControl sx={{ width: '400px' }}>
                          <SelectField
                            id="technician"
                            name="technician"
                            label="Technicien"
                            options={technicians}
                            errors={errors}
                            disabled={interventionIsReadOnly === true}
                          />
                        </FormControl>
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={6} />
                    )}
                    <Grid item xs={12} md={6}>
                      <FormControl sx={{ width: '400px' }}>
                        <TimePickerField
                          name="intervention_max_date"
                          id="intervention_max_date"
                          label="Date maximum d'intervention *"
                          disabled={interventionIsReadOnly === true}
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <h3>Finalité</h3>
                  <Stack spacing={2} sx={{ mb: 2 }}>
                    <FormControl>
                      <InputField
                        id="observation"
                        name="observation"
                        multiline
                        rows={4}
                        fullWidth
                        label="Observation (non visible sous-traitant) *"
                        disabled={interventionIsReadOnly === true}
                      />
                    </FormControl>
                    <FormControl>
                      <InputField
                        id="instructions"
                        name="specific_instructions"
                        multiline
                        rows={4}
                        fullWidth
                        label="Consignes particulières *"
                        disabled={interventionIsReadOnly === true}
                      />
                    </FormControl>
                    <FormControl sx={{ width: '400px' }}>
                      <SelectField
                        id="failure_code"
                        name="failure_code"
                        label="Code panne initiale *"
                        options={formatToSelectOptions(failureCodes)}
                        disabled={interventionIsReadOnly === true}
                      />
                    </FormControl>
                  </Stack>
                </>
              );
            }}
          </Formik>

          <Box sx={{ mb: 2 }}>
            <CrInterventionEvents
              crInterventionEvents={[...events, ...eventsToSave].sort((a: any, b: any) =>
                a.id.toString().localeCompare(b.id.toString())
              )}
              id={intervention?.id}
              eventToSave={(crInterventionEvent: CrInterventionEvent) => {
                const newEventToSave = Object.assign({}, crInterventionEvent);

                const step = interventionEvents?.find(
                  (step: any) => step.id === newEventToSave.step
                );
                newEventToSave.step__name = step?.label;

                let newEvents;
                if (!newEventToSave.id) {
                  // generate tmp id
                  newEventToSave.id = 'tmp-' + Math.floor(Math.random() * 1000000);
                  newEvents = [...eventsToSave, newEventToSave];
                } else {
                  let itemWithIdFound = false;
                  eventsToSave.forEach((eventToSave, index) => {
                    // si l'id match, on update cet item
                    if (eventToSave.id === newEventToSave.id) {
                      eventsToSave[index] = newEventToSave;
                      itemWithIdFound = true;
                    }
                  });

                  // si l'item était déjà existant en base on l'ajoute dans eventsToSave pour l'update et on le retire de events
                  if (!itemWithIdFound) {
                    eventsToSave.push(newEventToSave);
                    setEvents((events) => events.filter((event) => event.id !== newEventToSave.id));
                  }

                  newEvents = [...eventsToSave];
                }
                setEventsToSave(newEvents);
                onInterventionEventsChange(newEvents);
              }}
              readOnly={interventionIsReadOnly}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }
);
CrInterventionForm.displayName = 'CrInterventionForm';
export default CrInterventionForm;
