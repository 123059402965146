import React from 'react';
import { InterventionsFiltersContextProvider } from './context/interventionsFiltersContext';
import { Outlet } from 'react-router-dom';

export default function ExtractorInterventionsWrapper() {
  return (
    <InterventionsFiltersContextProvider>
      <Outlet />
    </InterventionsFiltersContextProvider>
  );
}
